.p-overlaypanel .p-overlaypanel-content {
  max-height: 75vh;
}

.selector-navbar {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background-color: #f7f7f7;
  position: sticky;
  top: 0;
  z-index: 11;
  img {
    vertical-align: middle;
  }
  .indicador {
    margin-left: 15px;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    flex-wrap: wrap;
    .pi.default {
      margin-right: 5px;
    }
    .pi:not(.default) {
      width: 24px;
      height: 24px;
      font-size: 24px;
      color: #808080;
    }
  }

  .nav--item {
    height: 50%;
    cursor: default;
    color: #0a1c29;
    &.dash {
      white-space: nowrap;
      cursor: pointer;
      border-bottom: 1px dashed black;
    }
  }
}

.container-video {
  max-width: 1500px;
  max-height: 900px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.p-dialog-header-icon {
  margin-top: 6px;
  margin-right: 20px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: black;
}

.custom-position-left {
  position: fixed !important;
  left: 50px !important;
  right: auto !important;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-5px);
  }
  60% {
      transform: translateY(-2px);
  }
}

.notification-badge {
  z-index: 9;
  position: absolute;
  top: 1px;
  right: 5px;
  background-color: rgb(255, 90, 95);
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  animation: bounce 2s infinite;
}

.menu-notification-badge {
  background-color: rgb(255, 90, 95);
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  top: 20px;
  right: 25px;
}
