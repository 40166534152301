.side-menu {
    padding: 15px;
    .side--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h1 {
        margin: 0;
        display: inline-block;
        color: #0a1c29;
    }
    .pi {
        font-size: 20px;
        cursor: pointer;
    }
}

.side--footer {
    position: fixed;
    bottom: 0;
    width: 290px;
}

.side--body {
    height: clamp(200px, 100vh, calc(100vh - 38.5px - 160.5px - (16px * 4)));
    overflow: auto;
}

.side--body, .side--footer {
    list-style-type: none;
    padding: 0;
    li {
        display: flex;
        align-items: center;
        color: #0a1c29;
        cursor: pointer;
        padding: 10px;
        background-color: rgb(0 117 179 / 10%);
        border-radius: 15px;
        margin-bottom: 10px;
        .pi, .item-logo {
            margin-right: 5px;
        }
        &:hover {
            background-color: rgb(0 117 179 / 20%);
        }
        &.active {
            background-color: #f292002e;
        }
    }
}