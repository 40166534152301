@keyframes glow {
  50% {
    border-color: #0075B3;
    box-shadow: 0 0 10px #0075B3;
  }
}

.sidebar {
  position: fixed;
  right: 0;
  width: 420px;
  background-color: #f6f6f6;
  overflow-y: auto;
  z-index: 9999;
  box-shadow: inset 0px 5px 10px -10px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease-in-out;
  transform: translateX(100%);
}

.sidebar.enter {
  transform: translateX(0);
}

.sidebar.exit {
  transform: translateX(100%);
}

.sidebar-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #4b5563;
  cursor: pointer;
}

.sidebar-content {
  padding-top: 40px;
  padding-right: 30px;
  padding-left: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-cards {
  flex: 1;
}

footer {
  border-top: #9d9d9c 1px solid;
  width: 100%;
  color: #4b5563;
  font-weight: 600;
}

.footer-link {
  color: #636b77;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
  .pi {
    margin-right: 5px;
  }
}

.footer-link:last-child {
  margin-bottom: 60px;
}

header {
  h3 {
    margin-top: 0;
    color: #4b5563;
  }
}

.sidebar-header-link-icon {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #c5d4dd;
  position: relative;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.sidebar-header-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  width: 120px;
  height: 120px;
  font-size: small;
  color: #4b5563;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &.glowing {
    animation: glow 3s infinite;
  }
  p {
    margin: 0;
    cursor: pointer;
  }
}

.sidebar-header-links {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.sidebar-card {
  margin-bottom: 20px;
  box-shadow: 0px 2px 10px -7px rgba(0, 0, 0, 0.4);
  .p-panel-header {
    background: #fff;
    border: 0;
    color: #4b5563;
    font-weight: 700;
    span {
      width: 280px;
    }
  }

  .p-panel-content {
    border: 0;
    background: #fbfbfb;
    padding-top: 16px;
    padding-bottom: 16px;
    p {
      margin-top: 0;
      font-size: 14px;
    }
  }
  
  ol li[data-list="bullet"]::marker {
    content: '• ' !important;
  }

}

.sidebar-card-link-icon {
  width: 72px;
  height: 72px;
  border-radius: 10px;
  background-color: #c5d4dd;
  position: relative;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transform: scale(0.78);
}

.sidebar-card-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  width: 120px;
  height: 120px;
  font-size: small;
  color: #4b5563;
  p {
    margin: 0;
    cursor: pointer;
  }
}

.sidebar-card-links {
  display: flex;
  justify-content: space-evenly;
}

.sidebar-card-footer {
  display: flex;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
  .sidebar-card-footer-text {
    font-size: 14px;
  }
  .sidebar-card-votes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .sidebar-card-vote {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      font-size: 0.7rem;
      cursor: pointer;
    }
  }
}

.insert-html {
  margin-bottom: 15px;
  p {
    margin-bottom: 3px;
    background-color: transparent;
  }
  strong {
    font-weight: 600;
  }
  ol {
    margin-bottom: 0;
    font-size: 14px;
  }
}

