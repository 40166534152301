.overlay-loading-bg {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: #80808040;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    span {
        z-index: 5;
    }

    .overlay-card {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.overlay-loading {
    height: 70px;
    width: 70px;
    z-index: 10;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 70px 70px;
}